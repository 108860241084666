import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import LaptopApp from './Components/LaptopApp';
import MobileApp from './Components/MobileApp';
import Blog from './Components/Blog';
import About from './Components/About';
import BuildingInstruction from './Components/buildingInstruction';
import ModularConstruction from './Components/modularConstruction';
import PropertyBuilding from './Components/propertyBuildings';
import SustainableBuildings from './Components/sustainableBuildings';
import TurnkeyConstruction from './Components/turnkeyConstruction';
import WoodGreen from './Components/woodGreen';
import EcoFriendlyPaint from './Components/ecoFriendlyPaint';
import CLTPanels from './Components/cltPanels';
import DronesConstruction from './Components/dronesConstruction';
import ManagementSoftware from './Components/managementSoftware';
import SafetyProcedure from './Components/safetyProcedure';
import Footer from './Components/footer';
import NewsUpdate from './Components/newsUpdate';
import Login from './Components/login';
import './App.css';

function App() {
  const isMobile = window.matchMedia("(max-width: 767px)").matches;

  return (
    <BrowserRouter>
      <div className="App">
        <Routes>
          <Route path="/" element={isMobile ? <MobileApp /> : <LaptopApp />} />
          <Route path="/Blog" element={<Blog />} />
          <Route path="/About" element={<About />} />
          <Route path="/buildingInstruction" element={<BuildingInstruction />} />
          <Route path="/modularConstruction" element={<ModularConstruction />} />
          <Route path="/propertyBuildings" element={<PropertyBuilding />} />
          <Route path="/sustainableBuildings" element={<SustainableBuildings />} />
          <Route path="/turnkeyConstruction" element={<TurnkeyConstruction />} />
          <Route path="/woodGreen" element={<WoodGreen />} />
          <Route path="/ecoFriendlyPaint" element={<EcoFriendlyPaint />} />
          <Route path="/cltPanels" element={<CLTPanels />} />
          <Route path="/dronesConstruction" element={<DronesConstruction />} />
          <Route path="/managementSoftware" element={<ManagementSoftware />} />
          <Route path="/safetyProcedure" element={<SafetyProcedure />} />
          <Route path="/NewsUpdate" element={<NewsUpdate />} /> {/* Add this line */}
          <Route path="/Login" element={<Login />} /> {/* Add this line */}
        </Routes>
        <Footer /> {/* Include the Footer component here */}
      </div>
    </BrowserRouter>
  );
}

export default App;
