import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../Components/newUpdate.css';

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();

  const handleLogin = () => {
    if (email === 'konceptsolution@gmail.com' && password === 'pakistan12345') {
      navigate('/NewsUpdate');
    } else {
      setErrorMessage('Invalid email or password');
    }
  };

  return (
    <div className="news-update-container">
      <div className="login-box">
        <h2>Login</h2>
        <div>
          <label>Email:</label>
          <input 
            type="email" 
            value={email} 
            onChange={(e) => setEmail(e.target.value)} 
          />
        </div>
        <div>
          <label>Password:</label>
          <input 
            type="password" 
            value={password} 
            onChange={(e) => setPassword(e.target.value)} 
          />
        </div>
        <button onClick={handleLogin}>Login</button>
        {errorMessage && <p>{errorMessage}</p>}
      </div>
    </div>
  );
}

export default Login;

