import React from 'react';
import ReactDOM from 'react-dom/client';
import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.js'

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .use(HttpApi)
  .init({
    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    supportedLngs:['en', 'ru', 'zh'],
     fallbackLng: "en",
    detection: {
      order: [ 'cookie', 'htmlTag','localStorage',  'path', 'subdomain'],
      caches:['cookie']

    },
    backend:{
      loadPath : '/assets/locales/{{lng}}/translation.json',
    },
    react : { useSuspense : false}

    
  });
  // function App (){
  //   const {t} = useTranslation()
  //   return <h2>{t('welcome_to_react')}</h2>
  // }
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
