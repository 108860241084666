import React, { useEffect } from 'react';
import '../Components/newUpdate.css';

function NewsUpdate() {
  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top of the page when component mounts
}, []);
  return (
    <div className="news-update-container">
      <header className="news-update-header">
        <h1>News & Updates</h1>
      </header>
      <div className="news-update-grid">
        {Array.from({ length: 6 }).map((_, index) => (
          <div key={index} className="news-update-item">
            {/* Add content for each news item here */}
          </div>
        ))}
      </div>
    </div>
  );
}

export default NewsUpdate;
