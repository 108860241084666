import React from 'react';

// Footer component
const Footer = () => {
    return (
        <footer style={{ backgroundColor: '#285352', padding: '20px', color: 'white', textAlign: 'center', borderRadius: '5px', marginTop: '10px' }}>
            <p style={{ marginBottom: '10px', fontSize: '1.1rem' }}>Contact Us</p>
            {/* Wrap the email address in an anchor tag */}
            <a href="mailto:admin@entergreenbuildings.com" style={{ marginBottom: '5px', fontSize: '1rem', color: 'white', textDecoration: 'none' }}>Email: admin@entergreenbuildings.com</a>
            <p style={{ marginBottom: '0', fontSize: '1rem' }}>Contact: 00971-564788746</p>
        </footer>
    );
};

// Main component containing your existing JSX
export default Footer;
