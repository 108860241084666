import React, { useState, useEffect } from 'react';
import Blog from './Blog';
import { Link } from 'react-router-dom';
import Translate from './Translate'; // Import the Translate component

function LaptopApp() {
  const [showVideo, setShowVideo] = useState(true);
  const [videoWidth, setVideoWidth] = useState('100%'); // Initial width set to 100%
  const [textWidth, setTextWidth] = useState('0%'); // Initial text width set to 0%
  const [textOpacity, setTextOpacity] = useState(0); // Initial text opacity set to 0
  const [hoveredCard, setHoveredCard] = useState(null);
  const [videoStyle, setVideoStyle] = useState({});
  const [textVisibility, setTextVisibility] = useState({
    text1: false,
    text2: false,
    text3: false,
  });
  const [videoEnded, setVideoEnded] = useState(false); // State to track if the video has ended
  const [imageOpacities, setImageOpacities] = useState({
    image1: 0.8,
    image2: 0.8,
    image3: 0.8,
  });
  const [loading, setLoading] = useState(true);

  const handleImageLoaded = () => {
    setLoading(false);
  };

  const handleMouseOver = (textId, imageId) => {
    setTextVisibility({ ...textVisibility, [textId]: true });
    setImageOpacities({ ...imageOpacities, [imageId]: 0.6 });
  };

  const handleMouseOut = (textId, imageId) => {
    setTextVisibility({ ...textVisibility, [textId]: false });
    setImageOpacities({ ...imageOpacities, [imageId]: 0.8 });
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setVideoWidth('50%');
      setTextWidth('50%');
      setTextOpacity(1);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      const windowHeight = window.innerHeight;

      const newWidth = `${Math.min(100, 50 + (scrollTop / windowHeight) * 50)}%`;
      setVideoWidth(newWidth);
      setTextWidth(newWidth);
      const newOpacity = Math.max(0, 1 - (scrollTop / windowHeight));
      setTextOpacity(newOpacity);
    };

    const smoothScroll = () => {
      handleScroll();
      requestAnimationFrame(smoothScroll);
    };

    window.addEventListener('scroll', handleScroll);
    requestAnimationFrame(smoothScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const cardNames = [
    'Eco-friendly Buildings', 'Why Us?', 'EGB: A Complete Eco Cycle', 'Our Expertise', 'Our Vision', 'Sustainable Design & Construction', ' AI Role in Sustainable Construction', 'Sustainable Finished Product'];
  const cardNames2 = [
    'About Us', 'Blogs', 'Company Profile', 'News and Updates',
  ];

  const details = [
    'As we look to the future of construction, eco-friendly green buildings stand out, and we are dedicated to leading this purposeful transformation.',
    'The benefits of Green buildings far outweigh all associated costs. In just a year, operating costs decreased by 10% and maintenance costs decreased by 20%. Lowering carbon emissions helps mitigate climate change, reducing the impact of global warming and extreme weather events.',
    'ENTER Green Buildings provides a complete solution that starts from eco-friendly designs, to recycling waste, to the use of environmentally friendly building materials, to the selection of the most advanced smart systems, and to the maintenance of finished projects',
    [
      'Eco-friendly designs',
      'Civil, Electrical, and Mechanical works',
      'Building Information Modelling (BIM) integral Planning',
      'Renewable energy and waste management',
      'Construction Waste Management',
    ],
    'Envisioning a greener tomorrow, we pioneer sustainable solutions in construction, harmonising innovation with environmental stewardship for a thriving planet. ',
    [
      'Unique and customer-centric Green designs.',
      'Selection and Procurement of Eco-friendly Materials.',
      'Energy efficiency and green technologies.',
      'Continuously assessing quality in the pursuit of excellence.',
      'Recycling of construction materials',
      'Smart systems'
    ],
    [
      'Ensuring safety standards.',
      'Minimising schedule delays.',
      'Preventing cost overruns.',
      'Improving profitability rates.',
    ],
    'The end product of sustainable building incorporates systems that lead to a greener environmental impact, quality of indoor environment, long term durability and resistance. As well as, cost and lifecycle performance.',
  ];
  const details2 = [
    <Link to="/About" style={{
      display: 'inline-block',
      padding: '10px 20px',
      textDecoration: 'none',
      color: '#fff',
      backgroundColor: '#007bff',
      border: '1px solid #007bff',
      borderRadius: '5px',
      transition: 'background-color 0.3s',
    }}>About Us</Link>,
    <Link to="/blog" style={{
      display: 'inline-block',
      padding: '10px 20px',
      textDecoration: 'none',
      color: '#fff',
      backgroundColor: '#007bff',
      border: '1px solid #007bff',
      borderRadius: '5px',
      transition: 'background-color 0.3s',
    }}>Visit Our Blog</Link>,
    <video
      id="videoFrame1"
      className="video-frame"
      title="Video Background"
      autoPlay
      loop
      muted
      onClick={() => {
        const video = document.getElementById('videoFrame1');
        if (!document.fullscreenElement) {
          video.requestFullscreen();
        } else {
          if (document.exitFullscreen) {
            document.exitFullscreen();
          }
        }
      }}
      style={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        height: '100%',
        width: '100%',
        borderRadius: '20px'
      }}
    >
      <source src="/Videos/main-video.mp4" type="video/mp4" />
      Your browser does not support the video tag.
    </video>
    ,
    <Link to="/NewsUpdate" style={{
      display: 'inline-block',
      padding: '10px 20px',
      textDecoration: 'none',
      color: '#fff',
      backgroundColor: '#007bff',
      border: '1px solid #007bff',
      borderRadius: '5px',
      transition: 'background-color 0.3s',
    }}>News And Updates</Link>  ];

  const handleMouseEnter = () => {
    setVideoStyle({
      transform: 'translateY(-10%)',
      transition: 'transform 0.5s ease-in-out'
    });
  };

  const handleMouseLeave = () => {
    setVideoStyle({
      transform: 'translateY(0)',
      transition: 'transform 0.5s ease-in-out'
    });
  };

  const handleVideoEnd = () => {
    setVideoEnded(true);
  };

  return (
    <div style={{ width: '100%', height: '100%', overflowX: 'hidden' }}>
      <div style={{ position: 'fixed', top: '10px', right: '10px', zIndex: '1000' }}>
        <Translate />
      </div>
      <div className="app-container" style={{ width: '99%', height: '100vh', display: 'flex' }}>
        <div className="video-container" style={{ width: videoWidth, height: '100%', position: 'relative', opacity: '5.5' }}>
          <video
            id="videoFrame"
            className="video-frame"
            title="Video Background"
            autoPlay
            loop
            muted
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
              borderTopLeftRadius: '20px',
              borderTopRightRadius: '20px',
              borderBottomLeftRadius: '0px',
              borderBottomRightRadius: '0px',
              marginTop: '10px',
              marginLeft: '10px',
            }}
          >
            <source src="/Videos/Moving_Gradient_Background.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          <img
            className="brand-logo"
            src="/Logos/Logo.png"
            alt="Brand Logo"
            style={{
              position: 'absolute',
              top: '55%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              maxHeight: '40%',
              maxWidth: '40%',
            }}
          />
          <h3
            className="tagline"
            style={{
              position: 'absolute',
              top: 'calc(30% + 50%)',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              fontSize: '24px',
              fontFamily: 'Red Hat, sans-serif',
              fontWeight: 'bold',
              color: 'white',
            }}
          >
            Redefining a Sustainable Future
          </h3>
        </div>
        <div
          style={{
            flex: '1',
            width: textWidth,
            backgroundColor: 'white',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            paddingTop: '3%',
            transition: 'opacity 0.5s ease-in-out, transform 0.5s ease-in-out',
            opacity: textOpacity,
            translate: 'none',
            rotate: 'none',
            visibility: 'inherit',
            transform: `translate(${textWidth === '50%' ? '0%' : textWidth}, 0px)`,
            textAlign: 'left',
            marginTop: '0px',
            marginLeft: '10px',
            position: 'relative'
          }}
        >
          <h3
            className="text-container"
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
              fontSize: '20px',
              fontStyle: 'normal',
              fontFamily: 'Red Hat, sans-serif',
              padding: '20px',
              margin: '0',
              color: 'rgba(0, 0, 0, 0.55)',
              lineHeight: "50px",
              textAlign: 'justify',
              hyphens: 'auto'
            }}
          >
            <b style={{
              marginBottom: "-40px",
              fontWeight: "bold",
              fontStyle: "italic"
            }}>
              Green And Sublime, Our Structures Align, With Nature's Grand Design.
            </b>
            <br />
            <span>
              Committed to redefining a sustainable future, we at Enter Green Buildings strive to revolutionize the construction by integrating cutting-edge green technologies and practices.
              <br /> Our mission is to lead the industry in creating Eco-friendly, Energy-efficient sustainable structures that not only enhance the well-being of occupants but also minimize our environmental footprint, paving the way for a greener and healthier community.
            </span>
          </h3>
        </div>
      </div>
      <div
        style={{
          width: '100%',
          height: '1000px',
          position: 'relative',
          borderRadius: '20px',
          overflow: 'hidden',
        }}
        className="lg:flex lg:justify-center lg:items-center lg:h-screen"
      >
        <video
          title="Video Background"
          autoPlay
          loop
          muted
          style={{
            width: '99%',
            height: '960px',
            objectFit: 'cover',
            marginTop: '5px',
            borderTopLeftRadius: '0px',
            borderTopRightRadius: '10px',
            borderBottomLeftRadius: '20px',
            borderBottomRightRadius: '20px',
            zIndex: 0,
          }}
          className="lg:h-full"
        >
          <source src="/Videos/Moving_Gradient_Background.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>

        <div
          style={{
            position: 'absolute',
            top: '0',
            left: '0',
            width: '100%',
            height: '100%',
            zIndex: 1000,
            marginTop: '95px',
            display: 'grid',
            gridTemplateColumns: 'repeat(4, 1fr)',
            gridTemplateRows: 'repeat(10, 1fr)',
            gap: '15px',
            padding: '10px',
          }}
        >
          {Array.from({ length: 8 }).map((_, index) => (
            <div
              key={index}
              className="card"
              style={{
                background: 'transparent',
                zIndex: 1000,
                padding: '20px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                border: '0.5px solid Grey',
                height: '350px',
                position: 'relative',
                cursor: 'pointer',
                overflow: 'hidden',
                borderRadius: '10px',
              }}
              onMouseEnter={() => setHoveredCard(index)}
              onMouseLeave={() => setHoveredCard(null)}
            >
              {hoveredCard === index && (
                <div
                  className="card-overlay"
                  style={{
                    position: 'absolute',
                    zIndex: 1000,
                    top: '0',
                    left: '0',
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'white',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    transition: 'background-color 0.3s ease-in-out',
                  }}
                >
                  {Array.isArray(details[index]) ? (
                    <ul style={{ textAlign: 'left', maxWidth: '275px', fontFamily: 'Red Hat, sans-serif', fontWeight: 'normal', fontSize: '16px', color: 'grey' }}>
                      {details[index].map((item, itemIndex) => (
                        <li key={itemIndex} style={{ marginBottom: '5px' }}>{item}</li>
                      ))}
                    </ul>
                  ) : (
                    <p style={{
                      fontFamily: 'Red Hat, sans-serif',
                      fontWeight: 'normal',
                      fontSize: '16px',
                      color: 'grey',
                      textAlign: 'center',
                      maxWidth: '275px',
                    }}>
                      {details[index]}
                    </p>
                  )}
                </div>
              )}
              <p style={{ fontFamily: 'Red Hat, sans-serif', fontWeight: 'bold', fontSize: '24px', color: 'white' }}>
                {cardNames[index]}
              </p>
            </div>

          ))}
        </div>
      </div>
      <div style={{ backgroundColor: 'white', padding: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', marginTop: '0px' }}>
        <img src="/Images/EGB_Slide.jpg" alt="Landscape Image" style={{ maxWidth: '90%', maxHeight: '90%', borderRadius: "20px", boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)', objectFit: 'cover' }} />
      </div>

      <div style={{
        width: '100%',
        maxHeight: '580px',
        position: 'relative',
        borderRadius: '20px',
        overflow: 'hidden',
      }} >
        <video
          className="video-frame-2"
          title="Video Background"
          autoPlay
          loop
          muted
          style={{
            width: '99%',
            maxHeight: '99%',
            objectFit: 'cover',
            marginTop: '10px',
            borderRadius: '10px',
          }}
        >
          <source src="/Videos/Moving_Gradient_Background.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div style={{
          position: 'absolute', top: '0', left: '0', width: '99%', height: '99%', marginTop: '100px', display: 'grid', gridTemplateColumns: 'repeat(4, 1fr)', gridTemplateRows: 'repeat(10, 1fr)', gap: '15px', padding: '10px', marginLeft: '6px'
        }}>
          {Array.from({ length: 4 }).map((_, index) => (
            <div
              key={index}
              className="card"
              style={{
                background: 'transparent',
                padding: '20px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                border: '0.5px solid Grey',
                height: '350px',
                position: 'relative',
                cursor: 'pointer',
                overflow: 'hidden',
                borderRadius: '10px',
              }}
              onMouseEnter={() => setHoveredCard(index)}
              onMouseLeave={() => setHoveredCard(null)}
            >
              {hoveredCard === index && (
                <div
                  className="card-overlay"
                  style={{
                    position: 'absolute',
                    top: '0',
                    left: '0',
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'white',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    transition: 'background-color 0.3s ease-in-out',
                  }}
                >

                  <p style={{
                    fontFamily: 'Red Hat, sans-serif', fontWeight: 'normal', fontSize: '16px', color: 'grey', textAlign: 'center', width: '275px', textAlign: 'center',
                  }}>
                    {details2[index]}
                  </p>
                </div>
              )}
              <p style={{ fontFamily: 'Red Hat, sans-serif', fontWeight: 'bold', fontSize: '24px', color: 'white' }}>
                {cardNames2[index]}
              </p>
            </div>
          ))}
        </div>
      </div>
      <div style={{ backgroundColor: 'white', padding: '20px', display: 'flex', height: '99%', }}>
        <div style={{
          marginTop: '90px',
          width: '60%',
          fontSize: '30px',
          fontStyle: 'normal',
          fontFamily: 'Red Hat, sans-serif',
          padding: '20px',
          marginTop: '100px',
          color: 'rgba(0, 0, 0, 0.55)',
          textAlign: 'justify' // Justify the text
        }}>
          <p
            style={{
              width: '90%',
              marginTop: '20px',
            }}>
            At Enter Green Buildings, we specialize in crafting sustainable green buildings that serve as architectural marvels as well as a reflection of our clients’ aspirations. Our process is rooted in creating bespoke designs that seamlessly integrate green spaces, passive cooling techniques, eco-friendly materials, and smart systems. From conception to completion, we prioritize innovation to minimize environmental impact and carbon footprint.
            Our mission is to bring to life sublime, energy-efficient green structures that not only exceed expectations but also contribute to a healthier planet. With a commitment to customer-centricity and sustainability, each project is a unique masterpiece aligned with our clients' vision and values. Our designs not only fulfil functional needs but also enable our clients to embrace a healthier lifestyle, distinguishing them from traditional structures and reflecting their individual identity and aspirations.
          </p>
          <p
            style={{
              width: '90%',
              marginTop: '20px',
            }}
          >
            A New World Envisioned
          </p>
        </div>
        <div style={{ marginTop: '90px', display: 'flex' }}>
          <div style={{ flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'flex-end' }}>
            <div style={{ position: 'relative' }}>
              <img
                src="/Images/Where4Green_Meets_Growth.jpg"
                alt="Where Green Meets Growth"
                style={{ width: '300px', height: '400px', alignSelf: 'center', marginTop: '405px', marginLeft: '50px', borderRadius: '30px', opacity: imageOpacities.image1 }}
                onMouseOver={() => handleMouseOver('text1', 'image1')}
                onMouseOut={() => handleMouseOut('text1', 'image1')}
              />
              <span
                id="text1"
                style={{ width: '180px', position: 'absolute', top: '605px', left: '200px', transform: 'translate(-50%, -50%)', color: 'white', fontSize: '24px', textAlign: 'center', fontWeight: 'bold', fontFamily: 'Red Hat, sans-serif', opacity: textVisibility.text1 ? '1' : '0', transition: 'opacity 0.3s', pointerEvents: 'none' }}
              >
                Where Green Meets Growth
              </span>
            </div>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
            <div style={{ position: 'relative' }}>
              <img
                src="/Images/B1.jpg"
                alt="Redefining a Sustainable Future"
                style={{ width: '300px', height: '400px', margin: '10px', marginTop: '200px', marginRight: '50px', borderRadius: '30px', opacity: imageOpacities.image2 }}
                onMouseOver={() => handleMouseOver('text2', 'image2')}
                onMouseOut={() => handleMouseOut('text2', 'image2')}
              />
              <span
                id="text2"
                style={{ position: 'absolute', top: '65%', left: '45%', transform: 'translate(-50%, -50%)', color: 'white', fontSize: '24px', textAlign: 'center', fontWeight: 'bold', fontFamily: 'Red Hat, sans-serif', opacity: textVisibility.text2 ? '1' : '0', transition: 'opacity 0.3s', pointerEvents: 'none' }}
              >
                Redefining a Sustainable Future
              </span>
            </div>
            <div style={{ position: 'relative' }}>
              <img
                src="/Images/C1.jpg"
                alt="Eco-Innovate"
                style={{ width: '300px', height: '400px', margin: '10px', marginRight: '50px', borderRadius: '30px', opacity: imageOpacities.image3 }}
                onMouseOver={() => handleMouseOver('text3', 'image3')}
                onMouseOut={() => handleMouseOut('text3', 'image3')}
              />
              <span
                id="text3"
                style={{ position: 'absolute', top: '50%', left: '46%', transform: 'translate(-50%, -50%)', color: 'white', fontSize: '24px', textAlign: 'center', fontWeight: 'bold', fontFamily: 'Red Hat, sans-serif', opacity: textVisibility.text3 ? '1' : '0', transition: 'opacity 0.3s', pointerEvents: 'none' }}
              >
                Eco-Innovate
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LaptopApp;
