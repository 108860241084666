import React, { useEffect } from 'react';
import '../Components/About.css';

function About() {
    useEffect(() => {
        window.scrollTo(0, 0); // Scrolls to the top of the page when component mounts
    }, []);

    return (
        <div className="about-container">
            <div className="ceo-message">
                <h2>CEO'S MESSAGE</h2>
                <div className="ceo-details">
                    <div className="ceo-text">
                        <h3>ALI JAFARLI</h3>
                        <h4>CEO & FOUNDER</h4>
                        <p>
                            "Here at Enter Green Buildings, We Dream Green, We Breathe Green. As the founder and CEO, I am passionate about making eco-friendly living a reality for everyone. Our team of experts is dedicated to crafting unique, customer-centric designs that incorporate cutting-edge sustainable technologies and eco-friendly materials from concept to completion. From innovative cooling systems to lush green spaces, we're committed to delivering luxurious, smart, and green solutions that not only reduce our environmental footprint but also enhance your quality of life. We utilize the latest technologies such as BIM, visualization, and project management tools to ensure seamless collaboration, efficient execution, and precise delivery. Our vision is to bridge the gaps between human and nature by creating harmonious spaces that not only promote sustainability but also foster a deeper connection between humans and their surroundings. What sets us apart is the exceptional talent and passion of our workforce, whose dedication, expertise, and commitment to sustainability brings our vision to life. Join us on this journey towards a greener tomorrow, and discover the difference and unique experience with Enter Green Buildings."
                        </p>
                    </div>
                    <img 
                        className='about-image'
                        src="/Images/about.png"
                        alt="Ali Jafarli, CEO & Founder"
                    />
                </div>
            </div>
        </div>
    );
}

export default About;
