import React, { useState, useEffect } from 'react';
import './modularConstraction.css'; // Import the CSS file

function BuildingInstruction() {
    useEffect(() => {
        window.scrollTo(0, 0); // Scrolls to the top of the page when component mounts
    }, []);
    return (
        <div className="containerHeader">
            {/* <video autoPlay loop muted className="video">
                <source src="/Videos/Moving_Gradient_Background.mp4" type="video/mp4" />
                Your browser does not support the video tag.
            </video> */}
            <div className="content">
                <h2 className="heading">
                    Building Information Modeling
                </h2>

                <p className="mainparagraph">
                    BIM (Building Information Modeling) solves the biggest problem facing the construction industry: collaboration. As the world grows smarter, so does our need for intelligent technology in construction. With BIM as the new standard in the industry, what was once a fantasy is now a reality.
                </p>

                <h3 className="subHeading">BIM in a nutshell</h3>
                <p className="paragraph smallParagraph">
                    BIM uses collaborative 3D modeling software to build an interactive, virtual super-blueprint. In terms of enhancing collaboration, this 3D model does far more than any two-dimensional model could ever dream of. This is BIM (Building Information Modeling) basics in 5 minutes or less.
                </p>

                <h3 className="subHeading">BIM, The problem solver</h3>
                <p className="paragraph smallParagraph">
                    No longer are architects, engineers, and builders at odds – about modeling anyway. With BIM, the conflicts of old are put to rest. In the past with previous modeling technology like blueprints, it was easy for conflicts to erupt because everyone involved was working on their own copy or version of a project. Then CAD prompted a swift evolution into 3D modeling and highlighted the greater need for collaboration.
                </p>

                <h3 className="subHeading">BIM In civil works</h3>
                <p className="paragraph smallParagraph">
                    Although BIM is primarily associated with the construction and building industry, we ought not to put a cap on its potential to work for us in other areas. Civil engineers, in particular, can benefit greatly from this collaborative tool.
                </p>
                <ul className="list smallParagraph">
                    <li>
                        <strong>Visualize the entire project:</strong> Civil engineers need to see the bigger picture of their projects, and BIM allows them to do so. With BIM, civil engineers can map out otherwise unseen areas, thus saving time, money, conflict, and manpower.
                    </li>
                    <li>
                        <strong>BIM levels:</strong> These levels go beyond 3D modeling. BIM software allows its users to map out up to four further dimensional elements such as the fourth dimension of time, a fifth for cost, a sixth for waste reduction, and even a seventh dimension of organization facilitation.
                    </li>
                    <li>
                        <strong>Realistic Modeling:</strong> BIM models incorporate and communicate real geospatial data, geometric infrastructure modeling, and limit the possibility for error.
                    </li>
                </ul>

                <h3 className="subHeading transition">BIM as a mainstay</h3>
                <p className="paragraph smallParagraph">
                    BIM is the present and foreseeable future of construction intelligent modeling. As the most comprehensive modeling technology to date, Business Information Modeling has changed the way builders, architects, and engineers work on projects. By removing longstanding causes of conflict and increasing knowledge, BIM saves the construction industry time, costs, and materials. Construction industry professionals, particularly in civil engineering, will continue to discover more ways that BIM is relevant to their work.
                </p>

                {/* <h4 className="subHeading transition">Company Name</h4> */}
                {/* <p className="paragraph smallParagraph">
                    LLC "Enter Construction" <br />
                    Legal address / Actual address <br />
                    115114, Moscow, Derbenevskaya embankment, house 11, floor 1 room 72, 72a, 72b, 72v <br />
                    INN: 9725037942 <br />
                    OGRN
                </p> */}
            </div>
        </div>
    );
}

export default BuildingInstruction;
