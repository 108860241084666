import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'; // Import Link component
import './MobileApp.css'; // Import the CSS file
import Translate from './Translate'; // Import the Translate component

function MobileApp() {
    const [showVideo, setShowVideo] = useState(true);
    const [showColor, setShowColor] = useState(true);
    const [videoWidth, setVideoWidth] = useState('100%');
    const [videoHeight, setVideoHeight] = useState('100vh');
    const [showText, setShowText] = useState(false); // Set initial state to false
    const [showCards, setShowCards] = useState(false);
    const [selectedCard, setSelectedCard] = useState(null);
    const [hoveredCard, setHoveredCard] = useState(null);

    const [textVisibility, setTextVisibility] = useState({
        text1: false,
        text2: false,
        text3: false,
    });

    const [imageOpacities, setImageOpacities] = useState({
        image1: 0.8,
        image2: 0.8,
        image3: 0.8,
    });

    const handleMouseOver = (textId, imageId) => {
        setTextVisibility({ ...textVisibility, [textId]: true });
        setImageOpacities({ ...imageOpacities, [imageId]: 0.6 });
    };

    const handleMouseOut = (textId, imageId) => {
        setTextVisibility({ ...textVisibility, [textId]: false });
        setImageOpacities({ ...imageOpacities, [imageId]: 0.8 });
    };
    const [isIOS, setIsIOS] = useState(false);
    const [isAndroid, setIsAndroid] = useState(false);

    useEffect(() => {
        const userAgent = navigator.userAgent.toLowerCase();
        setIsIOS(!!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform));
        setIsAndroid(userAgent.indexOf("android") > -1);
    }, []);

    useEffect(() => {
        const timer = setTimeout(() => {
            setShowVideo(true);
        }, 2000);

        return () => clearTimeout(timer);
    }, []);
    useEffect(() => {
        const timer = setTimeout(() => {
            setShowColor(true);
        }, 2000);

        return () => clearTimeout(timer);
    }, []);
    useEffect(() => {
        const timer = setTimeout(() => {
            setShowText(true); // Set to true after initial load
            setVideoWidth('90%'); // Set the desired width for the video
            setVideoHeight('45vh'); // Set the desired height for the video
            // setShowVideo(false); // Remove this line to keep the video displayed
        }, 2000);

        return () => clearTimeout(timer);
    }, []);

    useEffect(() => {
        const cardTimer = setTimeout(() => {
            setShowCards(true); // Set to true after text appears
        }, 1000); // Adjust delay as needed

        return () => clearTimeout(cardTimer);
    }, [showText]);

    const handleCardClick = (index) => {
        setSelectedCard(index);
    };
    const cardNames = [
        'Eco-friendly Buildings', 'Why Us?', 'EGB: A Complete Eco Cycle', 'Our Expertise', 'Our Vision', 'Sustainable Design & Construction', ' AI Role in Sustainable Construction', 'Sustainable Finished Product'];
    const cardNames2 = [
        'About Us', 'Blogs', 'Company Profile', 'News and  Updates',
    ];
    // const cardNames1 = [


    //     'Eco-FRIENDLY Buildings', 'Major Advantages', 'A Complete Eco Cycle', '', 'Contact Us', 'Our Expertise', 'Company Profile', 'Sustainable Construction', 'Sustainable Design Phase', 'Sustainable Construction Phase', 'Sustainable Finished Product', 'Blogs', ' ', ' ', ' ', '', '', ' ', ' ', ' ',
    // ];
    const details = [
        'As we look to the future of construction, eco-friendly green buildings stand out, and we are dedicated to leading this purposeful transformation.',
        'The benefits of Green buildings far outweigh all associated costs. In just a year, operating costs decreased by 10% and maintenance costs decreased by 20%. Lowering carbon emissions helps mitigate climate change, reducing the impact of global warming and extreme weather events.',
        'ENTER Green Buildings provides a complete solution that starts from eco-friendly designs, to recycling waste, to the use of environmentally friendly building materials, to the selection of the most advanced smart systems, and to the maintenance of finished projects',
        [
            'Eco-friendly designs',
            'Civil, Electrical, and Mechanical works',
            'Building Information Modelling (BIM) integral Planning',
            'Renewable energy and waste management',
            'Construction Waste Management',

        ],
        'Envisioning a greener tomorrow, we pioneer sustainable solutions in construction, harmonising innovation with environmental stewardship for a thriving planet. ',
        [
            'Unique and customer-centric Green designs.',
            'Selection and Procurement of Eco-friendly Materials.',
            'Energy efficiency and green technologies.',
            'Continuously assessing quality in the pursuit of excellence.',
            'Recycling of construction materials',
            'Smart systems'
        ],
        [
            'Ensuring safety standards.',
            'Minimising schedule delays.',
            'Preventing cost overruns.',
            'Improving profitability rates.',

        ],
        'The end product of sustainable building incorporates systems that lead to a greener environmental impact, quality of indoor environment, long term durability and resistance. As well as, cost and lifecycle performance.',
    ];
    const details2 = [
        <Link to="/About" style={{
            display: 'inline-block',
            padding: '10px 20px',
            textDecoration: 'none',
            color: '#fff',
            backgroundColor: '#007bff',
            border: '1px solid #007bff',
            borderRadius: '5px',
            transition: 'background-color 0.3s',
        }}>About Us</Link>,
        <Link to="/blog" style={{
            display: 'inline-block',
            padding: '10px 20px',
            textDecoration: 'none',
            color: '#fff',
            backgroundColor: '#007bff',
            border: '1px solid #007bff',
            borderRadius: '5px',
            transition: 'background-color 0.3s',
        }}>Visit Our Blog</Link>,
        <video
            id="videoFrame1"
            className="video-frame"
            title="Video Background"
            autoPlay
            loop

            onClick={() => {
                const video = document.getElementById('videoFrame1');
                if (!document.fullscreenElement) {
                    video.requestFullscreen();
                } else {
                    if (document.exitFullscreen) {
                        document.exitFullscreen();
                    }
                }
            }}
            style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                height: '100%',
                width: '100%',
                borderRadius: '20px'
            }}
        >
            <source src="/Videos/main-video.mp4" type="video/mp4" />
            Your browser does not support the video tag.
        </video>
        ,
        <Link to="/NewsUpdate" style={{
            display: 'inline-block',
            padding: '10px 20px',
            textDecoration: 'none',
            color: '#fff',
            backgroundColor: '#007bff',
            border: '1px solid #007bff',
            borderRadius: '5px',
            transition: 'background-color 0.3s',
        }}>News And Updates</Link>, // Updated for Contact Us

    ]

    return (
        <div className="main-film"
            style={{}}>
            <div style={{ width: '100%', height: '100vh', overflow: 'hidden' }}>
                <div style={{ position: 'fixed', top: '10px', right: '10px', zIndex: '1000' }}>
                    <Translate />
                </div>
                {showVideo && !isIOS && (
                    <div style={{
                        width: videoWidth,
                        height: videoHeight,
                        position: 'relative',
                        margin: '0 auto', // Centers the video container
                        transition: 'width 0.5s, height 0.5s',
                    }}>
                        <video
                            autoPlay
                            loop
                            muted
                            controls={false}
                            style={{
                                marginTop: showVideo ? '10px' : '20px',
                                width: '100%',
                                height: '100%',
                                objectFit: 'cover',
                                borderRadius: '15px'
                            }}
                        >
                            <source src="/Videos/Moving_Gradient_Background.mp4" type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                        <img
                            className="brand-logo"
                            src="/Logos/Logo.png"
                            alt="Brand Logo"
                            style={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                maxHeight: '50%',
                                maxWidth: '50%',
                                zIndex: '2'
                            }}
                        />
                        <h3
                            className="tagline"
                            style={{
                                position: 'absolute',
                                top: 'calc(30% + 50%)', // Adjust this value to properly position the tagline
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                fontSize: '1rem',
                                fontFamily: 'Red Hat, sans-serif',
                                fontWeight: 'bold',
                                color: 'white',
                                maxWidth: '100%'
                            }}
                        >
                            Redefining a Sustainable Future
                        </h3>
                    </div>
                )}
                {(showColor && isIOS) || !showVideo ? (
                    <div style={{
                        marginTop: showColor ? '10px' : '20px',
                        width: videoWidth,
                        height: videoHeight,
                        position: 'relative',
                        margin: '0 auto', // Centers the video container
                        transition: 'width 0.5s, height 0.5s',
                        objectFit: 'cover',

                        overflow: 'hidden',
                        backgroundColor: '#285352',
                        borderRadius: '15px' // Keep the same borderRadius for consistency
                    }}>
                        <img
                            className="brand-logo"
                            src="/Logos/Logo.png"
                            alt="Brand Logo"
                            style={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                maxHeight: '50%',
                                maxWidth: '50%',
                                zIndex: '2'
                            }}
                        />
                        <h3
                            className="tagline"
                            style={{
                                position: 'absolute',
                                top: 'calc(30% + 50%)', // Adjust this value to properly position the tagline
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                fontSize: '1rem',
                                fontFamily: 'Red Hat, sans-serif',
                                fontWeight: 'bold',
                                color: 'white',
                                maxWidth: '100%'
                            }}
                        >
                            Redefining a Sustainable Future
                        </h3>
                    </div>
                ) : null}
                {showText && (
                    <div  >
                        <h3
                            className="text-container"
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "start",
                                textAlign: 'left',
                                fontSize: '1rem',
                                fontStyle: 'normal',
                                fontFamily: 'Red Hat, sans-serif',
                                padding: '20px',
                                margin: '0',
                                // color: 'rgba(0, 0, 0, 0.55)',
                                color: 'black',
                                lineHeight: "25px"
                            }}
                        >
                            <b style={{
                                marginBottom: "0px",
                                fontWeight: "bold",
                                fontStyle: "italic"
                            }}>
                                Green And Sublime, Our Structures Align, With Nature's Grand Design.
                            </b>
                            <span>
                                Committed to redefining a sustainable future, we at ENTER GREEN BUILDINGS strive to revolutionize the construction by integrating cutting-edge green technologies and practices.
                                <br /> Our mission is to lead the industry in creating Eco-friendly, Energy-efficient sustainable structures that not only enhance the well-being of occupants but also minimize our environmental footprint, paving the way for a greener and healthier community.
                            </span>
                        </h3>
                    </div>
                )}
            </div>


            <div>
                {isIOS && (
                    <div style={{ backgroundColor: '#285352', width: '99.5%', height: '99%', objectFit: 'cover', marginTop: showVideo ? '0px' : '40px', borderRadius: '1px' }}>
                        <div style={{ width: '100%', height: '106vh', position: 'relative', marginTop: '10px', borderRadius: showVideo ? '0px' : '15px' }}>
                            {/* <video
                                title="Video Background"
                                autoPlay
                                loop
                                muted
                                controls={false}
                                style={{ width: '99.5%', height: '99%', objectFit: 'cover', marginTop: showVideo ? '0px' : '40px', borderRadius: '15px' }}
                            >
                                <source src="/Videos/Moving_Gradient_Background.mp4" type="video/mp4" />
                                Your browser does not support the video tag.
                            </video> */}
                            <div style={{ position: 'absolute', width: '99%', height: '9%', marginTop: showVideo ? '0px' : '40px', display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gridTemplateRows: 'repeat(16, 1fr)', gap: '0', padding: '0px', zIndex: '1' }}>
                                {Array.from({ length: 8 }).map((_, index) => (
                                    <div
                                        key={index}
                                        className="card"
                                        style={{
                                            background: 'transparent',
                                            padding: '20px',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            border: '0.5px solid Grey',
                                            height: '220px',
                                            position: 'relative',
                                            cursor: 'pointer',
                                            overflow: 'hidden',
                                            borderRadius: '15px'
                                        }}
                                        onMouseEnter={() => setHoveredCard(index)}
                                        onMouseLeave={() => setHoveredCard(null)}
                                    >
                                        {hoveredCard === index && (
                                            <div
                                                className="card-overlay"
                                                style={{
                                                    position: 'absolute',
                                                    top: '0',
                                                    left: '0',
                                                    width: '100%',
                                                    height: '100%',
                                                    backgroundColor: 'white',
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    flexDirection: 'column',
                                                    transition: 'background-color 0.3s ease-in-out',
                                                }}
                                            >
                                                {Array.isArray(details[index]) ? (
                                                    <ul style={{ textAlign: 'left', maxWidth: '215px', fontFamily: 'Red Hat, sans-serif', fontWeight: 'normal', fontSize: '10px', color: 'grey' }}>
                                                        {details[index].map((item, itemIndex) => (
                                                            <li key={itemIndex} style={{ marginBottom: '5px' }}>{item}</li>
                                                        ))}
                                                    </ul>
                                                ) : (
                                                    <p style={{
                                                        fontFamily: 'Red Hat, sans-serif',
                                                        fontWeight: 'normal',
                                                        fontSize: '9px',
                                                        color: 'grey',
                                                        textAlign: 'center',
                                                        maxWidth: '275px',
                                                    }}>
                                                        {details[index]}
                                                    </p>
                                                )}
                                            </div>
                                        )}
                                        <p style={{ fontFamily: 'Lemon Milk, sans-serif', fontWeight: 'bold', fontSize: '1rem', color: 'white' }}>
                                            {cardNames[index]}
                                        </p>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                )}

                {isAndroid && (
                    <div className="video-container-one" style={{ borderRadius: '15px' }}>
                        <video

                            autoPlay
                            loop
                            muted
                            controls={false}
                            style={{ marginTop: showVideo ? '0px' : '40px' }}
                        >
                            <source src="/Videos/Moving_Gradient_Background.mp4" type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                        <div style={{ position: 'absolute', top: '99.9px', left: '0', width: '99%', height: '9%', marginTop: showVideo ? '0px' : '40px', display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gridTemplateRows: 'repeat(16, 1fr)', gap: '0', padding: '0px', zIndex: '1' }}>
                            {Array.from({ length: 8 }).map((_, index) => (
                                <div
                                    key={index}
                                    className="card"
                                    style={{
                                        background: 'transparent',
                                        padding: '20px',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        border: '0.5px solid Grey',
                                        height: '220px',
                                        position: 'relative',
                                        cursor: 'pointer',
                                        overflow: 'hidden',
                                        borderRadius: '15px'
                                    }}
                                    onMouseEnter={() => setHoveredCard(index)}
                                    onMouseLeave={() => setHoveredCard(null)}
                                >
                                    {hoveredCard === index && (
                                        <div
                                            className="card-overlay"
                                            style={{
                                                position: 'absolute',
                                                top: '0',
                                                left: '0',
                                                width: '100%',
                                                height: '100%',
                                                backgroundColor: 'white',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                flexDirection: 'column',
                                                transition: 'background-color 0.3s ease-in-out',
                                            }}
                                        >
                                            {Array.isArray(details[index]) ? (
                                                <ul style={{ textAlign: 'left', maxWidth: '215px', fontFamily: 'Red Hat, sans-serif', fontWeight: 'normal', fontSize: '10px', color: 'grey' }}>
                                                    {details[index].map((item, itemIndex) => (
                                                        <li key={itemIndex} style={{ marginBottom: '5px' }}>{item}</li>
                                                    ))}
                                                </ul>
                                            ) : (
                                                <p style={{
                                                    fontFamily: 'Red Hat, sans-serif',
                                                    fontWeight: 'normal',
                                                    fontSize: '9px',
                                                    color: 'grey',
                                                    textAlign: 'center',
                                                    maxWidth: '275px',
                                                }}>
                                                    {details[index]}
                                                </p>
                                            )}
                                        </div>
                                    )}
                                    <p style={{ fontFamily: 'Lemon Milk, sans-serif', fontWeight: 'bold', fontSize: '1rem', color: 'white' }}>
                                        {cardNames[index]}
                                    </p>
                                </div>
                            ))}
                        </div>
                    </div>
                )}
            </div>


            <div style={{ backgroundColor: 'white', padding: '0px', display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '100px' }}>
                <img
                    src="/Images/EGB_Slide.jpg"
                    alt="Landscape Image"
                    style={{
                        maxWidth: '100%',
                        height: 'auto', // Allow the height to adjust based on the image's aspect ratio
                        borderRadius: "0px",
                        boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
                        objectFit: 'cover'
                    }}
                />
            </div>


            <div>
                {isIOS && (
                    <div style={{ backgroundColor: '#285352', width: '99.5%', height: '99%', objectFit: 'cover', marginTop: showVideo ? '0px' : '40px', borderRadius: '15px' }}>

                        <div style={{ width: '100%', height: '53vh', position: 'relative', marginTop: '100px', borderRadius: '15px' }}>
                            {/* <video
                            title="Video Background"
                            autoPlay
                            loop
                            muted
                            controls={false}
                            style={{ width: '99.5%', height: '99%', objectFit: 'cover', marginTop: showVideo ? '0px' : '40px', borderRadius: '15px' }}
                        >
                            <source src="/Videos/Moving_Gradient_Background.mp4" type="video/mp4" />
                            Your browser does not support the video tag.
                        </video> */}
                            <div style={{ position: 'absolute', top: '0', left: '0', width: '99%', height: '9%', marginTop: showVideo ? '0px' : '40px', display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gridTemplateRows: 'repeat(16, 1fr)', gap: '0', padding: '0px', zIndex: '1', borderRadius: '15px' }}>
                                {Array.from({ length: 4 }).map((_, index) => (
                                    <div
                                        key={index}
                                        className="card"
                                        style={{
                                            background: 'transparent',
                                            padding: '20px',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            border: '0.5px solid Grey',
                                            height: '220px',
                                            position: 'relative',
                                            cursor: 'pointer',
                                            overflow: 'hidden',
                                            borderRadius: '15px'
                                        }}
                                        onMouseEnter={() => setHoveredCard(index)}
                                        onMouseLeave={() => setHoveredCard(null)}
                                    >
                                        {hoveredCard === index && (
                                            <div
                                                className="card-overlay"
                                                style={{
                                                    position: 'absolute',
                                                    top: '0',
                                                    left: '0',
                                                    width: '100%',
                                                    height: '100%',
                                                    backgroundColor: 'white',
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    flexDirection: 'column',
                                                    transition: 'background-color 0.3s ease-in-out',
                                                }}
                                            >
                                                <p style={{ fontFamily: 'Lemon Milk, sans-serif', fontWeight: 'normal', fontSize: '0.65rem', color: 'grey', textAlign: 'center', width: '172px' }}>
                                                    {details2[index]}
                                                </p>
                                            </div>
                                        )}
                                        <p style={{ fontFamily: 'Lemon Milk, sans-serif', fontWeight: 'bold', fontSize: '1rem', color: 'white' }}>
                                            {cardNames2[index]}
                                        </p>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>

                )}

                {isAndroid && (
                    <div style={{ borderRadius: '15px', width: "100%", height: "600px", position: "relative", marginTop: "100px", overflow: "hidden", }}>
                        <video

                            autoPlay
                            loop
                            muted
                            controls={false}
                            style={{ marginTop: showVideo ? '0px' : '40px' }}
                        >
                            <source src="/Videos/Moving_Gradient_Background.mp4" type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                        <div style={{ position: 'absolute', top: '99.9px', left: '0', width: '99%', height: '9%', marginTop: showVideo ? '0px' : '40px', display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gridTemplateRows: 'repeat(16, 1fr)', gap: '0', padding: '0px', zIndex: '1', borderRadius: '15px' }}>
                            {Array.from({ length: 4 }).map((_, index) => (
                                <div
                                    key={index}
                                    className="card"
                                    style={{
                                        background: 'transparent',
                                        padding: '20px',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        border: '0.5px solid Grey',
                                        height: '220px',
                                        position: 'relative',
                                        cursor: 'pointer',
                                        overflow: 'hidden',
                                        borderRadius: '15px'
                                    }}
                                    onMouseEnter={() => setHoveredCard(index)}
                                    onMouseLeave={() => setHoveredCard(null)}
                                >
                                    {hoveredCard === index && (
                                        <div
                                            className="card-overlay"
                                            style={{
                                                position: 'absolute',
                                                top: '0',
                                                left: '0',
                                                width: '100%',
                                                height: '100%',
                                                backgroundColor: 'white',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                flexDirection: 'column',
                                                transition: 'background-color 0.3s ease-in-out',
                                            }}
                                        >
                                            <p style={{ fontFamily: 'Lemon Milk, sans-serif', fontWeight: 'normal', fontSize: '0.65rem', color: 'grey', textAlign: 'center', width: '172px' }}>
                                                {details2[index]}
                                            </p>
                                        </div>
                                    )}
                                    <p style={{ fontFamily: 'Lemon Milk, sans-serif', fontWeight: 'bold', fontSize: '1rem', color: 'white' }}>
                                        {cardNames2[index]}
                                    </p>
                                </div>
                            ))}
                        </div>
                    </div>
                )}
            </div>



            <div style={{ backgroundColor: 'white', padding: '20px', display: 'flex', flexDirection: 'column', marginTop: '50px', alignItems: 'flex-start' }}>
                <div style={{
                    color: 'black',
                    textAlign: 'justify',
                    fontFamily: 'Panton, sans-serif',
                    fontSize: '1.3rem',
                    lineHeight: '1.2',
                    marginBottom: '20px',
                }}>
                    <p style={{ marginTop: '0px', width: '100%', textAlign: 'justify', hyphens: 'auto' }}>
                        At Enter Green Buildings, we specialize in crafting sustainable green buildings that serve as architectural marvels as well as a reflection of our clients’ aspirations. Our process is rooted in creating bespoke designs that seamlessly integrate green spaces, passive cooling techniques, eco-friendly materials, and smart systems. From conception to completion, we prioritize innovation to minimize environmental impact and carbon footprint.
                        Our mission is to bring to life sublime, energy-efficient green structures that not only exceed expectations but also contribute to a healthier planet. With a commitment to customer-centricity and sustainability, each project is a unique masterpiece aligned with our clients' vision and values. Our designs not only fulfill functional needs but also enable our clients to embrace a healthier lifestyle, distinguishing them from traditional structures and reflecting their individual identity and aspirations.
                    </p>
                    <p style={{ marginTop: '20px', width: '50%', textAlign: 'left' }}>A New World Envisioned</p>
                </div>

                <div style={{ marginTop: '-300px', display: 'flex', alignItems: 'flex-start' }}>
                    <div style={{ position: 'relative' }}>
                        <img
                            src="/Images/Where4Green_Meets_Growth.jpg"
                            alt="Where Green Meets Growth"
                            style={{ maxWidth: '150px', height: '230px', alignSelf: 'center', marginTop: '335px', marginLeft: '50px', borderRadius: '30px', opacity: imageOpacities.image1 }}
                            onMouseOver={() => handleMouseOver('text1', 'image1')}
                            onMouseOut={() => handleMouseOut('text1', 'image1')}
                        />
                        <span
                            id="text1"
                            style={{ fontFamily: 'Panton, sans-serif', width: '100px', position: 'absolute', top: '460px', left: '115px', transform: 'translate(-50%, -50%)', color: 'white', fontSize: '1rem', textAlign: 'center', fontWeight: 'bold', fontFamily: 'Canela, serif', opacity: textVisibility.text1 ? '1' : '0', transition: 'opacity 0.3s', pointerEvents: 'none' }}
                        >
                            Where Green Meets Growth
                        </span>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', marginLeft: '20px' }}>
                        <div style={{ position: 'relative' }}>
                            <img
                                src="/Images/B1.jpg"
                                alt="Redefining a Sustainable Future"
                                style={{ fontFamily: 'Panton, sans-serif', maxWidth: '150px', height: '230px', margin: '10px', marginTop: '200px', marginRight: '50px', borderRadius: '30px', opacity: imageOpacities.image2 }}
                                onMouseOver={() => handleMouseOver('text2', 'image2')}
                                onMouseOut={() => handleMouseOut('text2', 'image2')}
                            />
                            <span
                                id="text2"
                                style={{ fontFamily: 'Panton, sans-serif', position: 'absolute', top: '72%', left: '42%', transform: 'translate(-50%, -50%)', color: 'white', fontSize: '1rem', textAlign: 'center', fontWeight: 'bold', fontFamily: 'Canela, serif', opacity: textVisibility.text2 ? '1' : '0', transition: 'opacity 0.3s', pointerEvents: 'none' }}
                            >
                                Redefining a Sustainable Future
                            </span>
                        </div>
                        <div style={{ position: 'relative' }}>
                            <img
                                src="/Images/C1.jpg"
                                alt="Eco-Innovate"
                                style={{ maxWidth: '150px', height: '230px', margin: '10px', marginRight: '50px', borderRadius: '30px', opacity: imageOpacities.image3 }}
                                onMouseOver={() => handleMouseOver('text3', 'image3')}
                                onMouseOut={() => handleMouseOut('text3', 'image3')}
                            />
                            <span
                                id="text3"
                                style={{ position: 'absolute', top: '50%', left: '42%', transform: 'translate(-50%, -50%)', color: 'white', fontSize: '1rem', textAlign: 'center', fontWeight: 'bold', fontFamily: 'Canela, serif', opacity: textVisibility.text3 ? '1' : '0', transition: 'opacity 0.3s', pointerEvents: 'none' }}
                            >
                                Eco-Innovate
                            </span>
                        </div>
                    </div>
                </div>
            </div>


        </div>
    );

}

export default MobileApp;
